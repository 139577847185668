body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfb871;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.logo_image {
  max-width: 100%;
  height: 120px;
}
.headerLeft {
  text-align: end;
  margin-top: 15px;
}
.headerLeft ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding-top: 10px;
}
.headerLeft ul li {
  display: inline-block;
}
.currency_icon {
  width: auto;
  height: 17px;
  margin-top: -3px;
  padding-left: 5px;
  padding-right: 2px;
}
.currency_arrow {
  width: auto;
  height: 10px;
  margin-top: -3px;
}
.headerLeftCurrency a {
  background: #fff;
  color: #000;
  border: #fff;
  box-shadow: none;
  outline: none;
  padding-top: 0;
}
.headerLeftCurrency a:hover {
  background: #fff;
  color: #000;
  border: #fff;
  box-shadow: none;
  outline: none;
}
.headerLeftCurrency a:focus {
  background: #fff !important;
  color: #000 !important;
  border: #fff !important;
  box-shadow: none !important;
  outline: none !important;
}
.headerLeftCurrency a:active {
  background: #fff;
  color: #000;
  border: #fff;
  box-shadow: none;
  outline: none;
}
.headerLeftCurrency.show a {
  background: #fff !important;
  color: #000 !important;
  border: #fff !important;
  box-shadow: none !important;
  outline: none !important;
  text-align: left;
  padding: 0.25rem 14px;
}

.headerLeftCurrency.show a:hover {
  color: #dfb871 !important;
}

.headerLeftCurrency a:after {
  display: none;
}
.langSwitcher {
  padding-left: 10px;
}
.langSwitcher a {
  color: #000;
  cursor: pointer;
}

.langSwitcher a:hover {
  color: #dfb871 !important;
}

.topLoginSeparator {
  padding-left: 3px;
  padding-right: 2px;
}
.menuDesktop {
  margin-top: 20px;
}
.menuDesktopRight ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: 12%;
  z-index: 9999999;
  margin-top: 3px;
}
.menuDesktopRight ul li {
  display: inline-block;
  margin-left: 19px;
  margin-right: 7px;
  margin-bottom: 25px;
}
.menuDesktopRight ul li a {
  font-size: 16px;
  color: #000;
}
.menuDesktopRight ul li a i {
  font-size: 20px;
}
.menuDesktopRight ul li a:hover {
  color: #dfb871;
  text-decoration: none;
}
.menuDesktopRight ul li.menuDesktopCategories {
  margin-right: 0;
  position: absolute;
  z-index: 999;
  top: -5px;
  cursor: pointer;
  right: -5px;
  font-weight: bold;
}

.menuDesktopRight ul li.menuDesktopCategories a {
  font-size: 26px;
}
.menuDesktopLeft {
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.menuDesktopLeft .form-inline {
  float: left;
}

.Media {
  position: absolute !important;
}
.menuDesktopLeft button {
  background: #dfb871;
  border-color: #dfb871;
  color: #fff;
}
.menuDesktopLeft button:hover {
  background: #fff;
  border-color: #dfb871;
  color: #000;
}

.hc-nav-trigger {
  display: none !important;
}
.closeMenuView {
  background: #dfb871;
  padding: 15px;

  margin-top: 20px;
}
.closeMenuViewRight h1 {
  color: #fff;
  font-size: 20px;
  margin: 0;
  padding: 0;
}
.closeMenuViewLeft {
  text-align: end;
}
.closeMenuViewLeft i {
  font-size: 24px;
  color: #fff;
}

.no_pad {
  padding: 0 !important;
}

.no_left_pad {
  padding-left: 0 !important;
}

.no_right_pad {
  padding-right: 0 !important;
}

/************** Home Page **************/
.HeaderHome {
  margin-top: 39px;
}

.item p {
  color: #000 !important;
  text-decoration: none;
}

.item:hover a {
  text-decoration: none;
}

.item:hover .Product {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 15px #0000001f;
}

.HeaderHome img {
  width: 100%;
  height: 523px;
}

.FirstImage img {
  width: 100%;
  height: 270px;
}

.HeaderHome h1 {
  position: absolute;
  bottom: 23%;
  left: 55px;
  color: #ffffff;
  font-size: 44px;
}

.HeaderHome h2 {
  position: absolute;
  top: 28px;
  right: 8%;
  color: #ffffff;
}

.HeaderSmallImage {
  margin-top: 30px;
}

.HeaderSmallImage img {
  height: 223px;
}

.HeaderHome h3 {
  position: absolute;
  top: 15px;
  left: 12%;
  color: #ffffff;
}

.HeaderHome h4 {
  position: absolute;
  bottom: 15px;
  right: 9%;
  color: #ffffff;
  font-size: 23px;
}

.ImageLayer {
  width: 100%;
  position: relative;
}

.ImageLayer::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.3;
  background: #000;
  background-size: cover;
}

/** Most sold **/
.MostSold {
  background: rgba(233, 233, 233, 0.56);
  margin-top: 50px;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 39px;
}
.slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  width: auto;
  max-width: 100%;
  height: 219px;
  margin: 0 auto;
}

.slick-slide .Product {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 0 9px #e0b97226;
}

.slick-slide .item {
  margin: 4px;
  cursor: pointer;
}

.slick-next:before,
.slick-prev:before {
  font-size: 45px;
  line-height: 1;
  opacity: 1.75;
  color: #131212;
}

.slick-prev {
  left: -52px;
}

.MostSold .caption h3 {
  color: #dfb871;
  margin-top: 11px;
  font-size: 22px;
}

.MostSold .btn-primary {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.MostSold .btn-primary:hover {
  color: #000000;
  background-color: #fff;
  transition: 0.5s;
}

.MostSold h1 {
  margin-bottom: 30px;
  padding-top: 11px;
}

/** Summer ***/
.Summer {
  background: url(/images/Path57.png) no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 20px;
  margin-top: 0px;
  text-align: center;
  padding-bottom: 29px;
}

.Recents {
  text-align: center;
  margin-top: 50px;
}

.Recents .btn-primary {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}

.Recents .btn-primary:hover {
  color: #000000;
  background-color: #fff;
  transition: 0.5s;
}

.Recents .slick-slide .Product {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 0 5px #0000001a;
}
.Recents .caption h3 {
  color: #dfb871;
  margin-top: 11px;
  font-size: 22px;
}

.Recents h1 {
  margin-bottom: 36px;
}

.footerRight {
  text-align: end;
  margin-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  height: 200px;
}
.footerRight ul {
  list-style-type: none;
  float: left;
}
.footerRight ul li {
  margin-bottom: 10px;
  direction: ltr;
}
.footerRight ul li a {
  color: #000;
  font-size: 18px;
}
.footerRight ul li a:hover {
  color: #dfb871;
  text-decoration: none;
}
.iframeDc {
  width: 215px;
  height: 211px;
  overflow: hidden !important;
  margin-top: -10;
  min-height: 100px;
  margin-bottom: -41px;
}
.footerLeft {
  text-align: start;
  margin-top: 80px;
  direction: rtl;
  border-right: 1px solid #777;
}
.footerLeft h1 {
  font-size: 30px;
}
.footerLeft h5 {
  font-size: 16px;
  margin-bottom: 20px;
}
.footerLeft button {
  background: #dfb871;
  border-color: #dfb871;
  color: #fff;
}
.footerLeft button:hover {
  background: #fff;
  border-color: #dfb871;
  color: #000;
}
.footerBottom {
  text-align: center;
}
.footerBottom h3 {
  font-size: 14px;
}

/*********************** Loader ********************/

.container22 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999999999999999;
  top: 0;
  overflow: hidden;
}
.displayShow {
  display: block !important;
}
.displayNone {
  display: none !important;
}

.dot-container {
  position: absolute;
  border: 0px solid #fff;
  border-radius: 50px;
  width: 100px;
  height: 100px;
}
.Spinner {
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 100px;
  height: 100px;
}
.dot-container:nth-child(2) {
  transform: rotate(40deg);
}
.dot-container:nth-child(2) .dot {
  animation-delay: -0.5s;
}
.dot-container:nth-child(3) {
  transform: rotate(80deg);
}
.dot-container:nth-child(3) .dot {
  animation-delay: -1s;
}

.dot {
  position: absolute;
  height: 10px;
  width: 10px;
  background: #dfb871;
  border-radius: 5px;
  transform: translate(0, 0);
}
.dot:nth-child(1) {
  top: 0;
  left: calc(50% - 5px);
  animation: ani1 2s infinite;
}
.dot:nth-child(2) {
  bottom: calc(28% - 5px);
  left: calc(11% - 5px);
  animation: ani2 2s infinite;
}
.dot:nth-child(3) {
  bottom: calc(28% - 5px);
  right: calc(11% - 5px);
  animation: ani3 2s infinite;
}

[hidden] {
  display: none;
}

@keyframes anirotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
@keyframes ani1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-40px, 67px);
  }
}
@keyframes ani2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(77px, 0);
  }
}
@keyframes ani3 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-39px, -67px);
  }
}

/******* All Category Page *************************/

.AllCatego {
  margin-top: 50px;
  min-height: 374px;
}

.AllCatego h1 {
  margin-top: 0px;
  color: #dfb871;
  border-right: 7px groove #dfb871;
  padding-right: 10px;
  -webkit-animation-name: example;
  -webkit-animation-duration: 4s;
  animation-name: example;
  animation-duration: 4s;
  font-size: 26px;
  margin-right: 4px;
  margin-bottom: 29px;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  from {
    color: #000;
  }
  to {
    color: #dfb871;
  }
}

/* Standard syntax */
@keyframes example {
  from {
    color: #000;
  }
  to {
    color: #dfb871;
  }
}
.Category img {
  width: auto;
  max-height: 173px;
  height: 173px;
  max-width: 100%;
}

.Category {
  text-align: center;
  padding: 0px;
  margin-top: 15px;
  border: none;
}
.Category .thumbnail {
  border: 3px solid #dfb871;
  border-radius: 6px;
  padding: 15px;
}
.Category:hover {
  box-shadow: none;
  /*
  box-shadow: 0 0 12px #00000059;*/
  transition: 0.5s;
}
.CategoryProducts .Category .thumbnail {
  border: none;
}
.AllCatego a {
  text-decoration: none;
}

.Category h3 {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 0;
  color: #2a2a2a;
}

.Category:hover h3 {
  color: #dfb871;
  transition: 0.5s;
}

.AllCatego .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 28%;
  margin-bottom: 33px;
  margin-top: 33px;
  text-align: right;
}

.AllCatego .input-group .form-control {
  float: right;
  background: #000;
  color: #fff;
  border: 3px solid #dfb871;
  outline: none !important;
  height: 45px;
  margin-right: 4px;
}

.input-group {
  outline: none !important;
}
.AllCatego .ButtonSearch {
  position: absolute;
  z-index: 0;
  left: 5px;
  top: 7px;
  border-radius: 3px !important;
  background: #dfb871;
  border: 1px solid #dfb871;
}

.AllCatego .ButtonSearch img {
  width: 17px;
}

.AllCatego .input-group .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.AllCatego .input-group .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.AllCatego .input-group .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.ReadyProduct .ButtonSearch {
  position: absolute;
  z-index: 0 !important;
  left: 45px;
  top: 71px;
  border-radius: 3px !important;
  background: #dfb871;
  color: #fff;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
  outline: none;
}

.container-fluid {
  width: 100%;
  padding-left: 15px;
  padding-right: 51px;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

/**************** Category Products *****************/
.Filters {
  box-shadow: 0 0 10px #dfb8714a;
  padding: 30px 33px;
  border: 1px solid #dfb8714a;
  padding-bottom: 64px;
}
.Filters h3 {
  font-size: 20px;
  color: #dfb871;
  margin-bottom: 39px;
}

.Filters .input-range {
  height: 1rem;
  position: relative;
  width: 90%;
  direction: ltr;
  margin-right: 17px;
}

.Filters .input-range__slider {
  background: #dfb871;
  border: 1px solid #dfb871;
  margin-top: -17px;
  width: 22px;
  height: 22px;
}

.Filters .input-range__track {
  height: 12px;
}

.Filters .input-range__track--active {
  background: #000000;
}

.Filters .input-range__label--value {
  position: absolute;
  top: -37px;
}

.Filters .input-range__label--min,
.input-range__label--max {
  bottom: -33px;
}

.Filters h4 {
  font-size: 20px;
  color: #dfb871;
  padding-bottom: 11px;
  margin-top: 60px;
}

.AllCategoProduct {
  min-height: 100px;
}

.CategoryProducts {
  margin-top: 45px;
}

.Category h2 {
  color: #8f8f8f;
  font-size: 18px;
  text-decoration: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.Category h4 {
  font-size: 16px;
  padding-top: 8px;
  color: #000;
  min-height: 50px;
}

.HeaderCart {
  margin-right: 6px;
  margin-left: 12px;
  color: black;
}

.HeaderCart a {
  font-size: 19px;
  margin-right: 6px;
  margin-left: 16px;
  color: black !important;
}
.HeaderCart img {
  width: 25px;
  margin-top: -6px;
}

.HeaderCart:hover {
  color: #dfb871;
}

.HeaderCart span {
  background: #dfb871;
  border-radius: 50%;
  font-size: 9px;
  color: #fff;
  padding: 2px 5px;
  text-align: center;
  position: relative;
  top: -22px;
  left: 20px;
}

.CategoryProducts .Category img {
  width: auto;
  max-height: 263px;
  height: 263px;
  /*
  max-width: 147px;*/
  max-height: 200px;
}
.CategoryProducts .Category .VideoPlay {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 2%;
  left: 7%;
}
.CategoryProducts .Category .InStock {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 1%;
  right: 6%;
}

.CategoryProducts .Category {
  text-align: center;
  padding: 17px;
  border: 1px solid rgba(223, 184, 113, 0.23);
  border-radius: 6px;
  margin-bottom: 30px;
}

.CategoryProducts .Category:hover {
  box-shadow: 0 0 12px rgba(223, 184, 113, 0.23);
  transition: 0.5s;
}

.CategoryProducts a {
  text-decoration: none;
}

.CategoryProducts .Category:hover h4 {
  color: #dfb871;
  transition: 0.5s;
}

.pretty .state label {
  position: initial;
  display: inline-block;
  font-weight: normal;
  margin: 0;
  text-indent: -1.5em;
  min-width: 0px;
  direction: ltr !important;
  margin-right: 0;
  padding-right: -1px;
}

.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #dfb871 !important;
}

.pretty .state label:before,
.pretty .state label:after,
.pretty .state label:before,
.pretty .state label:after {
  left: 10px;
}
.pretty input {
  min-width: 8em;
}

.pretty {
  margin-right: 25px;
  display: -webkit-box;
  text-align: -webkit-left;
  line-height: 1.1;
  margin-bottom: 9px;
}

.pretty.p-icon .state .icon {
  margin-left: 10px;
}

.Measur {
  float: right;
}

.FilterButt {
  /*
  position: absolute;
  bottom: 10px;
  display: flex;
  left: 10px;*/
}

.FilterButt .ButtonSearch {
  z-index: 9;
  border-radius: 3px !important;
  background: #dfb871;
  color: #fff;
  margin-left: 11px;
}

.ReactModal__Overlay {
  z-index: 99999999999999999999999;
}

.FilterIcon {
  display: none;
}

/******************** Product Details Page *****************/
.ProductDet h2 {
  font-size: 24px;
}

.ProductDet h3 {
  font-size: 21px;
  margin-top: 17px;
  color: #dfb872;
  margin-bottom: 17px;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 31px;
}

.ProductDet h4 {
  color: #909090;
  font-size: 16px;
  padding-bottom: 11px;
}

.ProductDet .br {
  margin-top: 14px;
  border-top: 2px solid #f1f1f1;
}

.ProductDet h5 {
  display: grid;
  color: #dfb871;
  line-height: 1.7;
  font-size: 16px;
  border-bottom: none;
  padding-bottom: 0px;
}

.ProductDet h5 h6 {
  padding-top: 0;
  display: -webkit-inline-box;
  color: #000;
  padding-right: 7px;
  font-size: 15px;
}
.ProductDet h5 h6 span {
  color: #dfb871;
}

.ProductDet h5 select {
  width: 178px;
  border: 1px solid #dfb871;
  padding: 4px 4px;
  text-align: right;
  outline: none !important;
}
.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.7rem;
  width: 3rem;
  font-size: 1.5rem;
  background: #f3f3f3;
  color: #888;
  border: 0 solid #dbdbdb;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.quantity-input__modifier:hover {
  background: #dadada;
  color: #555555;
}
.quantity-input__modifier--left {
  border-radius: 3px 0 0 3px;
}
.quantity-input__modifier--right {
  border-radius: 0 3px 3px 0;
}

.quantity-input__screen {
  width: 4rem;
  padding: 0.7rem;
  font-size: 1.5rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
}

.QntFavAdd {
  display: -webkit-inline-box;
  padding-top: 22px;
  margin-top: 18px;
}

.ProductDet .QntFavAdd h3 {
  border-bottom: none;
  margin-top: 4px;
  padding-left: 26px;
  font-size: 19px;
}

.AddToCart {
  background: #dfb871;
  border: 2px solid #dfb871;
  border-radius: 40px;
  padding: 4px 16px;
  margin-left: 7px;
  margin-right: 40px;
  color: #fff;
  outline: none !important;
  font-size: 16px;
  border: 1px solid transparent;

  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.AddToCart:hover {
  background: #fff;
  color: #dfb871;
  transition: 0.5s;
  border-color: #dfb871;
}
.NotShowByOrder {
  background: #353232;
  border: 2px solid #353232;
  border-radius: 40px;
  padding: 4px 16px;
  margin-left: 7px;
  margin-right: 40px;
  color: #fff;
  outline: none !important;
  font-size: 16px;
  border: 1px solid transparent;

  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.AddToFav {
  border: none;
  padding: 6px 10px;
  border-radius: 40px;
  outline: none;
  position: relative;
  float: left;
}

.AddToFav:hover {
  background: #000;
  color: #dfb871;
  transition: 0.5s;
}

.RemoveToFav {
  border: none;
  padding: 6px 10px;
  border-radius: 40px;
  outline: none;
  position: relative;
  float: left;
  background: #000;
  color: #dfb871;
}

.QntButt {
  background: #dfb871;
  border: 2px solid #dfb871;
  color: #fff;
  border-bottom-right-radius: 7px;
}

.QntButtLeft {
  background: #dfb871;
  border: 2px solid #dfb871;
  color: #fff;
  border-top-left-radius: 7px;
}

.inputne {
  border: 2px solid #dfb871;
  text-align: center;
  width: 26%;
}

.ProductDetails .slick-next {
  right: 40% !important;
  bottom: 0;
  top: 122% !important;
  width: 50px;
  height: 50px;
}
.ProductDetails .slick-prev {
  left: 29% !important;
  bottom: 0;
  top: 122% !important;
  width: 50px;
  height: 50px;
}
.ProductDetails {
  border: 3px solid #dfb871;
  padding: 8px;
  padding-bottom: 61px;
  margin-bottom: 26px;
  border-radius: 10px;
}

.ProductDetailsVideo {
  border: 3px solid #dfb871;
  padding: 8px;
  padding-bottom: 8px;
  margin-bottom: 26px;
  border-radius: 10px;
  margin-top: 90px;
}

.ProductDet {
  padding-right: 20px;
}
#mabg {
  position: absolute;
  top: 10px;
  left: 220px;
  right: auto;
  width: auto;
  height: 700px;
}

.AvailableCircle {
  color: #0c0c0c;
}

.AvailableCircle .fa {
  color: green;
}

.UnAvailableCircle {
  color: #0c0c0c;
}

.UnAvailableCircle .fa {
  color: red;
}

.AccessoriesSlider h1 {
  margin-bottom: 8px;
  border-right: 5px groove #dfb871;
  padding-top: 0;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  border-left: 5px groove #dfb871;
  font-size: 30px;
}
.AccessoriesSlider h4 {
  padding-top: 0;
  padding-bottom: 15px;
  font-size: 21px;
  color: #212529;
}

.AccessoriesSlider {
  background-color: #dfb87173;
  background-image: url(https://www.transparenttextures.com/patterns/absurdity.png);
  margin-top: 50px;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 23px;
  margin-bottom: -50px;
}

.AccessoriesSlider .slick-slide .Product {
  background: #ffffff;
  padding: 10px;
  border: 1px groove;
}

.AccessoriesSlider .slick-slide .Product h5 {
  font-size: 15px;
  color: #212529;
}

.AccessoriesSlider .slick-slide:hover .Product h5 {
  color: #dfb871;
  transition: 0.5s;
}

.item .Product h5 {
  font-size: 15px;
  color: #212529;
  text-align: center;
  padding-top: 19px;
}

.item :hover .Product h5 {
  color: #dfb871;
  transition: 0.5s;
}

.Filterxs {
  display: inline;
  box-shadow: none;
  border: none;
}

.modal-footer .btn-primary {
  color: #fff;
  background-color: #dfb871;
  border: 2px solid #dfb871;
  border-radius: 11px;
}

.modal-footer .btn-primary:hover {
  color: #dfb871;
  background-color: #fff;
}

.modal-footer .btn-secondary {
  color: #fff !important;
  background-color: #6c757d;
  border: 2px solid #6c757d;
  border-radius: 10px;
}

.modal-footer .btn-secondary:hover {
  color: #6c757d !important;
  background-color: #fff;
}

.CloseFilterModal {
  position: absolute;
  top: 6px;
  left: 0;
  background: none;
  border: none;
}

a {
  text-decoration: none !important;
}
.ButtonContin {
  background: #000 !important;
  color: #ffffff !important;
  cursor: pointer;
  right: auto;
}

.CartTable img {
  width: 100px;
}

table {
  width: 100%;
}

.CartTable {
  border: 3px solid #dfb871;
  margin-top: 68px;
  border-radius: 17px;
  padding: 18px;
  margin-bottom: 70px;
}

.CartTable .nav-tabs {
  padding-right: 0;
  padding-left: initial;
  position: absolute;
  top: -37px;
  background: #ffffff;
  padding: 14px 0;
  border-bottom: none;
}

.CartTable .tab-content {
  margin-top: 23px;
  border: none;
  padding: 13px;
  border-radius: 7px;
  margin-bottom: 65px;
}

.CartTable .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  color: #000000;
  margin-left: 23px;
  margin-right: 3px;
}

.CartTable .nav-tabs .nav-item.show .nav-link,
.CartTable .nav-tabs .nav-link.active {
  color: #f0cf8c;
  background-color: #fff;
  border-color: #fadd9f #dfb871 #f3d99a;
}

td,
th {
  text-align: center !important;
  padding: 8px;
}

tr {
  border-bottom: 1px solid #dddddd;
}
tr:last-child {
  border-bottom: none;
}

.CartTable a {
  color: #dfb871;
}

.CartTable a:hover {
  color: #000;
  transition: 0.5s;
}

.MessageSuccess {
  padding-top: 10px;
  font-size: 19px;
  text-align: right;
}

.SuccessContainer {
  text-align: center;
}

.CartbuttContainer {
  text-align: center;
  width: 100%;
  margin-top: 53px;
  margin-bottom: 11px;
}

.CartbuttContainer .fa {
  padding-left: 6px;
}
.Cartbutt {
  background: #dfb871;
  border: 2px solid #dfb871;
  padding: 8px;
  color: #ffffff !important;
  border-radius: 7px;
  cursor: pointer;
}

.Cartbutt:hover {
  background: #ffffff;
  color: #dfb871 !important;
  transition: 0.5s;
}

/* Style the input container */
.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

/* Style the form icons */
.icon {
  padding: 17px 14px;
  background: white;
  color: #dfb871;
  min-width: 50px;
  text-align: center;
  font-size: 17px;
}
/* Style the input fields */
.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border: 2px solid transparent;
  border-bottom: 2px solid #eae6e6;
  outline: none;
}

.input-field:focus {
  border: 2px solid #dfb871;
  transition: 0.5s;
}

.CheckoutTabel {
  width: 96%;
  margin: 0 26px;
  margin-bottom: 61px;
}

#basic img {
  width: auto;
  height: 27px;
  margin-top: 16px;
  margin-left: 9px;
  margin-right: 14px;
}

.TotalPriceCount {
  margin-top: 47px;
  margin-right: 12px;
}

.TotalPriceCount h4 {
  display: -webkit-inline-box;
  font-size: 16px;
  margin-top: 0px;
  color: #dfb871;
}
.TotalPriceCount h5 {
  color: #000;
  padding-right: 12px;
  font-size: 17px;
  padding-left: 8px;
}

.redeemBut {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 16px;
  bottom: 26px;
  outline: none !important;
}

.Paymentmethod h3 {
  padding: 8px 4px 27px 6px;
  color: #dfb871;
  font-size: 18px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: #f4ffff;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.btn-link {
  font-weight: 400;
  color: #dfb871 !important;
  text-decoration: none;
}

.btn-link:hover {
  color: #000 !important;
  text-decoration: none !important;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: none !important;
  color: #000 !important;
  box-shadow: none;
}

.PaymentInfo h5 {
  font-size: 16px;
}

.PaymentInfo label {
  display: inline-block;
  margin-bottom: 16px;
  color: #dfb871;
}
.PaymentInfo {
  padding-bottom: 27px;
}

/********************* About *************/

.About p {
  padding-top: 17px;
  padding-bottom: 7px;
  font-size: 17px;
  line-height: 1.7;
  margin-right: 23px;
}

.MyProfile {
  color: #0c0c0c;
}

.MyProfile:hover {
  color: #dfb871;
}

.headerLeftCurrency .dropdown-menu.show {
  display: block !important;
}

.nav-pills > li {
  margin-right: 2px;
  margin-left: auto;
  text-align: center;
  background: #dfb871;
  color: #000;
  padding: 13px;
  border-radius: 7px;
  width: 33%;
}

.nav-pills {
  padding-right: 0;
  padding-left: initial;
  margin-top: 46px;
}

.nav-pills > li > a {
  border-radius: 4px;
  color: white;
}

.tab_icon {
  width: auto;
  height: auto;
  margin-top: 0;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.tab-content {
  margin-top: 23px;
  border: 4px solid #dfb871;
  padding: 13px;
  border-radius: 7px;
  margin-bottom: 65px;
}

.tab-content h3 {
  padding-top: 21px;
  padding-bottom: 17px;
  font-size: 17px;
  color: #dfb871;
}
.tab-content a {
  color: #dfb871;
  padding-right: 9px;
}

.tab-content a:hover {
  color: #ccc;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #0c0c0c !important;
  background-color: #dfb871 !important;
}

.nav-pills .nav-link.active img {
  filter: hue-rotate(175deg) brightness(0.2);
}

.PressHere {
  color: #dfb871;
}

.PressHere:hover {
  color: #ccc;
}

.City {
  background-color: #2a2a2a;
  background-position: center;
  padding: 8px;
  margin-top: 23px;
  padding-top: 22px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.deleviryCalc {
  background: #dfb871;
  padding: 19px;
  text-align: center;
  color: #000;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-bottom: 43px;
}

.deleviryCalc h3 {
  color: #fff;
  font-size: 21px;
  margin-top: 29px;
}

.deleviryCalc .tab_icon {
  width: auto;
  height: 100px;
}

.borderDeleviry {
  border-left: 2px solid;
}

.Deleviry {
  border: none;
  margin-bottom: 0;
}

/************** ContactPage ************/

.ContactInfo {
  margin-top: 63px;
}
.ContactInfo h3 {
  display: flex;
  font-size: 15px;
  color: #dfb871;
  line-height: 2.3;
  margin-bottom: 13px;
  text-align: right;
}

.ContactInfo h4 a {
  color: rgba(0, 0, 0, 0.685);
  padding-right: 9px;
}

.getintouch {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: -9px;
  color: #5d5c5a;
  margin-right: -36px;
}

.getintouch a {
  color: #dfb871;
}

.getintouch a:hover {
  color: #ccc;
}

.Agreement {
  margin-top: 15px;
}
.accordion {
  margin-bottom: 12px;
  border-bottom: 2px solid #e8e8e8;
}
/*************** Profile **************/
.Profile .nav-pills {
  padding-right: 0;
  padding-left: initial;
  margin-top: 23px;
  display: block;
}
.Profile .nav-pills > li {
  margin-right: 2px;
  margin-left: auto;
  text-align: center;
  background: #dfb871;
  color: #000;
  padding: 19px;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 20px;
}

#menu2 .item img {
  width: 100%;
  height: 243px;
}

#menu2 .item .Product {
  border: 2px solid #f5f5f5;
  padding: 4px;
  margin-top: 20px;
}

#menu2 .item:hover .Product {
  background: #ffffff;
  padding: 4px;
  box-shadow: 0px 0px 15px #0000001f;
}

#menu2 p {
  padding-top: 0;
  font-size: 17px;
}

#menu2 h3 {
  padding-top: 21px;
  padding-bottom: 12px;
  font-size: 17px;
  color: #dfb871;
}

#menu2 .ProfileTrash {
  float: left;
  padding-left: 8px;
  cursor: pointer;
  position: absolute;
  top: 52px;
  left: 19px;
}
#menu2 .ProfileTrash:hover {
  color: #dfb871;
}

#menu2 .fa {
  font-size: 17px;
}

.Media {
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 999;
}
.Media ul li:hover img {
  filter: saturate(3);
  transition: 0.5s;
  box-shadow: 0 0 6px #00000021;
}

.Media ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  top: 37%;
  z-index: 9999999999999999;
  left: 0px;
}

.Media ul li {
  margin-bottom: 7px;
  background: #dfb871;
  padding: 6px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.dropdown-menu.show {
  display: contents !important;
  position: absolute !important;
}

/* Customize the label (the container) */
.containercHECK {
  display: -webkit-inline-box;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  line-height: 1.1;
}

/* Hide the browser's default checkbox */
.containercHECK input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 4px;
  height: 25px;
  width: 25px;
  padding-right: 25px;
  background-color: #eee;
  margin-left: 0px;
  font-size: 17px;
  padding-top: 4px;
}



.CheckDiv {
  margin-bottom: 0px;
  display: -webkit-box;
  direction: initial;
}
/* On mouse-over, add a grey background color */
.containercHECK:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containercHECK input:checked ~ .checkmark {
  background-color: #dfb871;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containercHECK input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containercHECK .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.CheckDiv p {
  /* padding-left: 15px; */
  font-size: 16px;
  padding-top: 7px;
  margin-right: 8px;
  width: 202px;
}

.Agreement .CheckDiv p {
  width: 100%;
}

.Agreement .CheckDiv {
  margin-right: 32px;
}

.PaymentCjeck .CheckDiv p {
  direction: rtl;
}

.mega-menu2 {
  background: hsl(0, 0%, 100%) !important;
  margin-right: 10px !important;
  height: 318px;
  overflow-y: scroll;
  padding-top: 28px;
  box-shadow: 0 0 12px #ada9a933;
  border: 3px solid #f5f5f5;
  border-radius: 7px;
}

.mega-menu2::-webkit-scrollbar {
  width: 0px;
}

.CategoryThumb {
  padding: 9px;
  border-radius: 7px;
  margin-bottom: 7px;
  text-align: right;
}

.CategoryThumb img {
  width: 40px;
  margin-left: 5px;
}

.img-fluid {
  max-width: 100%;
  height: 150px !important;
}

.menuDesktopRight ul li.menuDesktopCategories a {
  font-size: 15px;
  color: #000;
}

.menuDesktopRight ul li.menuDesktopCategories a:hover {
  color: #dfb871;
}

.CatalogColors {
  background: #ffffff;
  margin-top: 17px;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  display: -webkit-inline-box;
  border-top: 2px solid rgba(171, 170, 169, 0.17);
  width: 100%;
  border-radius: 0;
  padding-top: 30px;
  border-bottom: 2px solid rgba(171, 170, 169, 0.17);
  margin-bottom: 8px;
  padding-bottom: 19px;
}

.CatalogColors h3 {
  font-size: 21px;
  margin-top: -8px;
  color: #dfb872;
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.CatalogColors input[type="radio"]:checked + label:before {
  height: 26px;
  width: 26px;
}

.CatalogColors h1 {
  font-size: 22px;
  text-transform: uppercase;
  border-bottom: 2px solid #eae2e2c7;
  padding-bottom: 15px;
  padding-left: 18px;
  color: #8f8f8f;
  font-family: OpenSans;
}

.CatalogColors ul {
  list-style: none;
  padding-left: 4px;
  display: -webkit-box;
  margin-right: -26px;
}

.CatalogColors ul li {
  margin-top: 0;
}

.CatalogColors input[type="radio"] {
  visibility: hidden;
}

.CatalogColors label {
  cursor: pointer;
  color: #7b7979;
  font-size: 16px;
  font-weight: 100;
  margin-left: 7px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(195, 193, 193, 0.67);
  padding: 2px 6px;
}

.CatalogColors p {
  line-height: 1.1;
}

.CatalogColors input[type="radio"] + label:before {
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 2px;
}

.CatalogColors input[type="radio"]:checked + label:before {
  background: #000;
  color: #333;
  content: "\2713";
  text-align: center;
}

/** Black */

.CatalogColors .Black input[type="radio"] + label:before {
  border: 1px solid #131313;
  background: #131313;
}

.CatalogColors .Black input[type="radio"]:checked + label:before {
  background: #131313;
  color: #131313;
}

.CatalogColors .Black input[type="radio"]:checked + label {
  color: #131313;
}

/** Blue Color */
.CatalogColors .Blue input[type="radio"] + label:before {
  border: 1px solid #1b77ea;
  background: #1b77ea;
}

.CatalogColors .Blue input[type="radio"]:checked + label:before {
  background: #1b77ea;
  color: #1b77ea;
}

.CatalogColors .Blue input[type="radio"]:checked + label {
  color: #1b77ea;
}

/** Red Color */

.CatalogColors .Red input[type="radio"] + label:before {
  border: 1px solid #f62e24;
  background: #f62e24;
}

.CatalogColors .Red input[type="radio"]:checked + label:before {
  background: #f62e24;
  color: #f62e24;
}

.CatalogColors .Red input[type="radio"]:checked + label {
  color: #f62e24;
}

/** Green **/

.CatalogColors .Green input[type="radio"] + label:before {
  border: 1px solid #11bf46;
  background: #11bf46;
}

.CatalogColors .Green input[type="radio"]:checked + label:before {
  background: #11bf46;
  color: #11bf46;
}

.CatalogColors .Green input[type="radio"]:checked + label {
  color: #11bf46;
}

/** Orange **/

.CatalogColors .Orange input[type="radio"] + label:before {
  border: 1px solid #f6a924;
  background: #f6a924;
}

.CatalogColors .Orange input[type="radio"]:checked + label:before {
  background: #f6a924;
  color: #f6a924;
}

.CatalogColors .Orange input[type="radio"]:checked + label {
  color: #f6a924;
}

/** Gray **/

.CatalogColors .Gray input[type="radio"] + label:before {
  border: 1px solid #afacac;
  background: #afacac;
}

.CatalogColors .Gray input[type="radio"]:checked + label:before {
  background: #afacac;
  color: #afacac;
}

.CatalogColors .Gray input[type="radio"]:checked + label {
  color: #afacac;
}

/** Brown **/

.CatalogColors .Brown input[type="radio"] + label:before {
  border: 1px solid #97613b;
  background: #97613b;
}

.CatalogColors .Brown input[type="radio"]:checked + label:before {
  background: #97613b;
  color: #97613b;
}

.CatalogColors .Brown input[type="radio"]:checked + label {
  color: #97613b;
}

.CatalogColors input[type="radio"]:checked + label:before {
  background: transparent;
  color: #fff;
  content: "\2713";
  text-align: center;
  padding-top: 6px;
  padding-left: 3px;
}

.select-size input {
  display: none;
}

.ItemsSize {
  display: inline-flex;
  padding-top: 0px;
  /* border-top: 2px solid #e8e1e1; */
  width: 100%;
  padding-bottom: 11px;
  border-bottom: 2px solid #f1f1f1;
  margin-bottom: 13px;
  flex-wrap: wrap;
}
.select-size {
  margin-right: 23px;
}

.ItemsSize label {
  display: inline-block;
  width: auto;
  height: auto;
  text-align: center;
  line-height: 33px;
  cursor: pointer;
  border-radius: 12px;
  background: #353232;
  margin-top: 12px;
  margin-left: 3px;
  color: #fff;
  padding: 5px 15px;
}

.ItemsSize h3 {
  margin-top: 15px;
  color: #dfb872;
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
  font-size: 17px;
}
.ItemsSize input:checked + label {
  background: #dfb871;
  color: #fff;
}

.ItemsSize input:disabled + label {
  background: #dfb871;
  color: #fff;
  opacity: 0.2;
}

.SuccessContainer a {
  font-size: 20px;
}

.homeCategoryElementNew {
  text-align: center;
  padding: 5px;
}
.homeCategoryElementNew h1 {
  font-size: 18px;
  margin-top: 5px;
}
.homeCategoryElementNew img {
  width: 100%;
  min-height: auto;
}
.homeCategoryElementNewImages {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.catHomeTitleBottomNew {
  color: #000;
}

.homeCategoryElement img {
  width: 100%;
  height: 100%;
}
.homeCategoryDiv {
  margin-top: 50px;
}
.homeCategoryElement {
  text-align: center;
}
.homeCategoryElement h1 {
  font-size: 20px;
  margin-top: 5px;
}

.catHomeTitleBottom {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  color: #fff;
}

.slick-next {
  right: -60px !important;
  top: 50%;
  width: 45px;
  height: 45px;
}
.slick-prev {
  left: -44px !important;
  top: 50%;
  width: 45px;
  height: 45px;
}

.input-range__label--max {
  display: none;
}
.input-range__label--min {
  display: none;
}

.ProductDetails .slick-slide img {
  display: block;
  width: auto !important;
  max-width: 100%;
  height: 474px !important;
  margin: 0 auto;
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "\f0a9";
  font-family: "FontAwesome";
}

.slick-prev:before {
  content: "\f0a8";
  font-family: "FontAwesome";
}

.MobileCartView {
  display: none;
}

.menuDesktopCategoriesBG a {
  background: #dfb871;
  padding: 7px 10px 10px 10px;
  border-radius: 20px;
}
.menuDesktopCategoriesBG a:hover {
  background: #000;
  color: #fff !important;
}

.medias ul {
  list-style: none;
  text-align: center;
  margin-bottom: 0;
}
.medias ul li {
  margin-bottom: 7px;
  background: #dfb871;
  padding: 6px;
  border-radius: 7px;
  display: inline-block;
  margin-left: 10px;
}
.mobileTopSearch .form-inline {
  width: 100%;
}
.mobileTopSearch input {
  width: 78% !important;
}
.mobileTopSearch button {
  width: 18% !important;
  margin-right: 1% !important;
  margin-left: 1% !important;
}

.seasonOvere {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-bottom: 90px;
}

.Summer .slick-next:before,
.Summer .slick-prev:before {
  color: #ffffff;
}

.whatsappDiv {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
.whatsappDiv img {
  width: 100px;
}

.medias {
  margin-top: 10px;
}

.no_overview {
  overflow: hidden;
}

.no_margin {
  margin: 0;
}

.modal {
  z-index: 99999999999 !important;
}
.Profile .thumbnail {
  text-align: center;
}
#menu2 .item img {
  width: auto;
  height: 200px;
  margin: 0 auto;
}

.About p {
  margin-right: auto;
  padding: 5px;
}
#menu2 h3 {
  text-align: center !important;
}
.Profile .caption p {
  text-align: center !important;
}

.forgotPassword {
  margin-top: 20px;
}
.forgotPassword a {
  cursor: pointer;
  color: #d6a345;
  font-weight: bold;
}

.redmmm {
  background: #dfb871;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  padding-bottom: 7px;
}

.oldPriceStyle {
  margin-top: 0 !important;
  line-height: 1.5 !important;
  text-decoration: line-through !important;
  color: #6f6c6c !important;
}

.CategoryProducts .Category .thumbnail {
  min-height: 230px;
}

.CategoryProducts .Category img {
  height: auto;
}

.creditLogss img {
  max-height: 85px;
  height: 100%;
  max-width: 100%;
}

.thumbnailDiscountView {
  background: #e44f43;
  width: 100%;
  padding: 3px;
  border-radius: 50px;
}

.thumbnailDiscountView h5 {
  margin: 0;
  padding: 0;
  color: #fff;
  margin-bottom: 3px;
  font-size: 18px;
}

.thumbnailColorsiew {
  background: #dfb871;
  width: 100%;
  padding: 3px;
  border-radius: 50px;
  position: absolute;
  width: 115px;
  left: 20px;
  top: 20px;
}

.thumbnailColorsiew h5 {
  margin: 0;
  padding: 0;
  color: #fff;
  margin-bottom: 3px;
  font-size: 14px;
}











.creditLogss22 {
  padding: 0;
}
.creditLogss22 img {
  max-height: 80px;
  height: 70px;
  width: 200px !important;
  object-fit: contain;
  max-width: 100% !important;
}

/*************** Media Queries ************/

@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .CategoryProducts .Category img {
    width: auto;
    max-height: 263px;
    /* height: 226px;
    
  max-width: 239px;*/
    max-height: 200px;
    height: auto;
  }

  .Profile .nav-pills > li {
    width: 100% !important;
    margin-bottom: 3px;
    padding: 7px;
  }

  .menuDesktopRight ul {
    margin-right: 12%;
  }

  .CategoryThumb img {
    width: 45px;
    margin-left: 5px;
  }

  .menuDesktopRight ul li.menuDesktopCategories a {
    font-size: 16px;
  }

  .homeCategoryElement h1 {
    font-size: 20px;
  }
}
@media (min-width: 767.99px) and (max-width: 991.98px) {
  .menuDesktopRight ul li {
    margin-left: 8px;
    margin-right: 8px;
  }
  .homeCategoryElement h1 {
    font-size: 20px;
  }
  .ProductDetails .slick-slide img {
    width: 100%;
    height: auto !important;
    max-height: 300px;
  }
  .Category h3 {
    font-size: 14px;
    min-height: 22px;
  }
  .Category img {
    max-height: 140px;
  }

  .AllCatego .input-group {
    width: 49%;
  }
  .CategoryProducts .Category img {
    width: auto;
    max-height: 263px;
    /* height: 226px;
    /*
  max-width: 239px;*/
    max-height: 200px;
    height: auto;
  }
  .CategoryProducts .Category img {
    max-height: 140px;
  }
  .AddToCart {
    padding: 4px 12px;
    margin-left: 7px;
    margin-right: 11px;
    font-size: 13px;
  }
  .no_pad_xs {
    padding: 0;
  }
  .FilterIcon {
    display: inline;
    background: none;
    border: 2px solid #000;
    position: fixed;
    z-index: 99999999;
    background: #000;
    left: 0;
    border-radius: 6px;
    top: 34%;
    outline: none !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .FilterIcon img {
    width: 32px;
    height: 32px;
  }
  .AccessoriesSlider h1 {
    width: 73%;
  }

  .menuDesktopRight ul {
    margin-right: 15%;
  }

  .menuDesktopRight ul li a {
    font-size: 14px;
  }
  .CategoryThumb img {
    width: 18px;
    margin-left: 7px;
  }
  .menuDesktopRight ul li.menuDesktopCategories a {
    font-size: 16px;
  }

  .inputne {
    width: 16%;
  }

  .QntFavAdd {
    margin-right: 62px;
  }

  .headerLeft ul li {
    font-size: 12px;
  }

  .headerLeftCurrency a {
    font-size: 12px;
  }

  .getintouch {
    margin-right: 2px;
  }
  .CategoryProducts .Category .thumbnail {
    min-height: 170px;
  }
}
@media (min-width: 767.99px) {
  .hc-offcanvas-nav.disable-body {
    display: none !important;
  }
  .col-md-4 {
    display: inline-block;
  }
  .ContactInfo h4 a {
    font-size: 15px;
  }

  .getintouch {
    line-height: 1.7;
  }
  .slick-next {
    right: -11px;
  }

  .slick-prev {
    left: -28px;
  }

  .QntFavAdd {
    display: -webkit-inline-box;
    padding-top: 22px;
    margin-top: 18px;
    margin-right: 64px;
  }
  .menuDesktopRight ul {
    z-index: 1;
  }

  
}
@media (min-width: 767.99px) and (max-width: 991.98px) {
  .Filters {
    display: none;
  }
  .redeemBut {
    bottom: 10px !important;
  }
  .cktsoutInput .input-container {
    margin-bottom: 5px;
}
}
@media (min-width: 577px) and (max-width: 766px) {
  .no_left_pad {
    padding-left: 0 !important;
  }

  .no_right_pad {
    padding-right: 0 !important;
  }

  .HeaderHome h4 {
    font-size: 17px;
  }

  .CategoryProducts .Category img {
    width: auto;
    max-height: 263px;
    /* height: 226px;
    
    max-width: 239px;*/
    max-height: 200px;
    height: auto;
  }

  .ProductDet .QntFavAdd h3 {
    display: inline;
  }
  .TotalPriceCount h4 {
    display: flex;
    width: 442px;
  }

  .borderDeleviry {
    border-left: 2px solid !important;
    border-bottom: none !important;
    padding-bottom: 17px;
    margin-bottom: 26px;
  }
  .ReactModal__Content {
    width: 83% !important;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .mobileTopSearch input {
    width: 66% !important;
  }
}

@media (max-width: 767.98px) {
  .headerTop {
    text-align: center;
  }
  .footerLeft {
    border-right: none;
  }
  .medias ul,
  .footerRight ul {
    padding: 0;
  }
  .CategoryProducts .Category .thumbnail {
    min-height: 160px;
  }
  .enBlockDisplay {
    display: block;
  }
  .footerBottom {
    margin-top: 40px;
  }
  .Category img {
    /*
    max-height: 140px;*/
    max-height: 100%;
    height: auto;
  }
  .CategoryProducts .Category img {
    max-height: 130px;
    height: auto;
  }
  .HeaderHome h4 {
    bottom: 19%;
  }
  .no_pad_xs {
    padding: 0;
  }
  .no_left_pad {
    padding: 2px !important;
  }
  .ProductDetails {
    padding: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }
  .ProductDetails .slick-slide img {
    width: 100%;
    height: auto !important;
    max-height: 300px;
  }
  .no_right_pad {
    padding: 2px !important;
  }

  .slick-slide img {
    /*
    height: auto;*/
  }

  .ReactModal__Content .slick-slide img {
    height: 409px;
  }

  .slick-slide .item {
    margin: 0px;
    width: 90%;
  }

  .slick-next {
    right: 5% !important;
    top: 108%;
  }

  .slick-prev {
    left: 17% !important;
    top: 108%;
  }

  .Media ul {
    top: 50%;
  }

  .MostSold {
    padding-bottom: 96px;
  }

  .Recents {
    padding-bottom: 57px;
  }

  .HeaderSmallImage {
    margin-bottom: 30px;
  }

  .Child {
    margin-bottom: 30px;
  }

  .AllCatego .input-group {
    width: 84%;
    margin-top: 107px;
  }

  .Filters {
    display: none;
  }

  .Filterxs {
    display: inline;
    box-shadow: none;
    border: none;
  }

  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .CloseFilterModal {
    position: absolute;
    top: 6px;
    left: 0;
    background: none;
    border: none;
  }

  .FilterIcon {
    display: inline;
    background: none;
    border: 2px solid #000;
    position: fixed;
    z-index: 99999999;
    background: #000;
    left: 0;
    border-radius: 6px;
    top: 34%;
    outline: none !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .FilterIcon img {
    width: 32px;
    height: 32px;
  }
  .ProductDetails .slick-prev {
    left: 24%;
    top: 107%;
  }

  .ProductDetails .slick-next {
    right: 37%;
    top: 107%;
  }

  .ProductDet h2 {
    margin-top: 45px;
  }

  .ProductDet {
    padding-right: 0;
    margin-top: 70px;
  }

  #mabg {
    bottom: 10px;
    left: 15px;
    right: auto;
  }

  .AddToCart {
    margin-left: 9px;
    margin-right: 9px;
    font-size: 13px;
  }

  .inputne {
    width: 15%;
  }

  .ReadyProduct .ButtonSearch {
    left: -10px;
    top: 91px;
    font-size: 13px;
    z-index: -1;
  }
  .productReadXS .ButtonSearch {
    top: 40px;
  }
  .headerLeft {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .menuDesktopLeft {
    padding-left: 13px;
    padding-right: 13px;
  }
  .mobileTopSearch input {
    width: 66% !important;
  }
  .mobileTopSearch button {
    width: 30% !important;
  }
  .ProductDet .QntFavAdd h3 {
    display: none;
  }

  .QntMob {
    width: 100%;
  }

  .CartTable .nav-tabs {
    top: 0;
  }
  #basic {
    margin-top: 22px;
  }

  .MostSold h1 {
    font-size: 25px;
  }
  .tab-content {
    padding: 7px 0;
  }
  .CartTable .nav-tabs .nav-item.show .nav-link,
  .CartTable .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid;
  }
  .CartTable .nav-tabs .nav-link {
    margin-left: 2px;
    margin-right: 3px;
    font-size: 13px;
  }
  .ReactModal__Content {
    left: 0px !important;
    right: 0px !important;
    top: 0px !important;
    bottom: 0px !important;
    width: 100% !important;
    z-index: 999999999999999999999 !important;
  }

  .RowCart {
    border-bottom: 2px solid #dfb871;
    margin-bottom: 43px;
    padding-bottom: 22px;
  }

  .MobileCart {
    display: none;
  }

  .MobileCartView {
    display: inline;
  }

  .MobileCartView .CartImage {
    text-align: center;
  }

  .MobileCartView img {
    width: 151px;
  }

  .MobileCartView h3 {
    font-size: 18px;
    margin-left: 11px;
  }

  .MobileCartView .InfoCart {
    display: flex;
    line-height: 2;
    margin-bottom: 18px;
  }

  .DeleteInfoCart {
    position: absolute;
    left: 0;
    right: auto;
    font-size: 28px;
  }
  .DeleteInfoCart .fa {
    position: absolute;
    left: 0;
  }

  .ButtonContin {
    right: 26px !important;
    left: auto;
  }
  .AllCatego h1 {
    font-size: 22px;
  }

  .AccessoriesSlider h1 {
    width: 73%;
  }

  .inputne {
    width: 40%;
  }

  .CartTable {
    overflow-x: auto;
  }

  .nav-pills > li {
    width: 48%;
    margin-bottom: 9px;
  }

  .borderDeleviry {
    border-left: none;
    border-bottom: 2px solid;
    padding-bottom: 17px;
    margin-bottom: 26px;
  }
  .ContactInfo h3 {
    font-size: 13px;
    line-height: 1;
    text-align: start;
  }
  .ContactInfo h4 {
    line-height: 0.5;
  }
  .ContactInfo h4 a {
    font-size: 16px;
  }

  .getintouch {
    line-height: 1.7;
    width: 100%;
    margin-right: 0;
  }
  .Profile .nav-pills > li {
    padding: 5px;
  }
  .ReadyProduct {
    position: absolute;
    left: 18%;
    width: 100%;
    bottom: 50%;
    z-index: 0;
    top: auto;
  }

  .slick-next {
    width: 110px;
    height: 110px;
  }
  .slick-prev {
    width: 110px;
    height: 110px;
  }

  .ProductDetails .slick-next {
    right: 16% !important;
    bottom: 0;
    top: 122% !important;
    z-index: 9999;
  }
  .ProductDetails .slick-prev {
    left: 12% !important;
    bottom: 0;
    top: 122% !important;
    z-index: 99999;
  }

  /*
.hc-offcanvas-nav.hc-nav-1 {
  display: none !important;
}*/
  .footerRight ul {
    float: none;
  }
  .footerRight {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .ProductDet h5,
  .ItemsSize h3 {
    font-size: 18px;
  }
  .ProductDet h5 h6 {
    padding-top: 0;
  }
  .nav-link {
    font-size: 11px;
  }
  .checkGuestForms {
    padding-left: 0;
    padding-right: 0;
  }
  .checkGuestForms .input-field {
    font-size: 12px;
  }
  .checkGuestForms .col-xs-12 {
    padding: 0 !important;
  }
  .checkGuestForms .icon {
    min-width: 30px;
  }
  .CartTable .nav-tabs .nav-link {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .redeemBut {
    bottom: 10px !important;
  }
  .cktsoutInput .input-container {
    margin-bottom: 5px;
}

.medias {
  margin-top: 75px !important;
}

}
@media (max-width: 575.98px) {
  .Profile .nav-pills > li {
    width: 48%;
    margin-bottom: 3px;
  }
  .homeCategoryElement h1 {
    font-size: 20px;
  }
  .mobileTopSearch input {
    width: 68% !important;
  }
  .CategoryProducts .Category .thumbnail {
    min-height: 140px;
  }

  .CheckoutTabel{
    margin: 0;
  }
}

@media (max-width: 420px) {
  .Category {
    padding: 5px;
  }
  .Category img {
    /*
    max-height: 120px;*/
    max-height: 100%;
    height: auto;
  }
  .homeCategoryElementNew img {
    /*
    min-height: 160px;*/
    max-height: 100%;
    min-height: 0;
  }
  .homeCategoryElementNewImages {
    /*
    height: 160px;*/
    height: auto;
  }
  .Category h3 {
    font-size: 14px;
    margin-top: 10px;
  }
  .cktsoutSelect {
    padding: 0;
  }
  .cktsoutSelect img,
  .cktsoutInputIms {
    display: none;
  }
  .cktsoutInput {
    padding: 0;
    min-width: 310px;
  }
}

/*Menu Styles*/
html.hc-nav-yscroll {
  overflow-y: scroll;
}
body.hc-nav-open {
  overflow: visible;
  position: fixed;
  width: 100%;
  min-height: 100%;
}
.hc-offcanvas-nav {
  visibility: hidden;
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 9999;
}
.hc-offcanvas-nav.is-ios * {
  cursor: pointer !important;
}
.hc-offcanvas-nav .nav-container {
  position: fixed;
  z-index: 9998;
  top: 0;
  width: 240px;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.hc-offcanvas-nav .nav-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  box-sizing: border-box;
}
.hc-offcanvas-nav .nav-content {
  height: 100%;
  max-height: 100vh;
}
.hc-offcanvas-nav .nav-wrapper-0 > .nav-content {
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;
}
.hc-offcanvas-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.hc-offcanvas-nav li {
  position: relative;
  display: block;
}
.hc-offcanvas-nav li.level-open > .nav-wrapper {
  visibility: visible;
}
.hc-offcanvas-nav input[type="checkbox"] {
  display: none;
}
.hc-offcanvas-nav label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
}
.hc-offcanvas-nav a {
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
}
.hc-offcanvas-nav a,
.hc-offcanvas-nav a:hover {
  text-decoration: none;
}
.hc-offcanvas-nav .nav-item {
  position: relative;
  display: block;
  box-sizing: border-box;
}
.hc-offcanvas-nav.disable-body::after,
.hc-offcanvas-nav .nav-wrapper::after {
  content: "";
  position: fixed;
  z-index: 9990;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0.4s, opacity 0.4s ease;
}
.hc-offcanvas-nav.disable-body.nav-open::after,
.hc-offcanvas-nav .sub-level-open::after {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.05s;
}
.hc-offcanvas-nav:not(.nav-open)::after {
  pointer-events: none;
}
.hc-offcanvas-nav.nav-levels-expand .nav-content {
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;
}
.hc-offcanvas-nav.nav-levels-expand .nav-wrapper::after {
  display: none;
}
.hc-offcanvas-nav.nav-levels-expand ul .nav-wrapper {
  min-width: 0;
  max-height: 0;
  overflow: hidden;
  transition: height 0s ease 0.4s;
}
.hc-offcanvas-nav.nav-levels-expand .level-open > .nav-wrapper {
  max-height: none;
}
.hc-offcanvas-nav.nav-levels-overlap .nav-content {
  overflow: scroll;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;
}
.hc-offcanvas-nav.nav-levels-overlap ul .nav-wrapper {
  position: absolute;
  z-index: 9999;
  top: 0;
  height: 100%;
  visibility: hidden;
  transition: visibility 0s ease 0.4s, -webkit-transform 0.4s ease;
  transition: visibility 0s ease 0.4s, transform 0.4s ease;
  transition: visibility 0s ease 0.4s, transform 0.4s ease,
    -webkit-transform 0.4s ease;
}
.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {
  position: static;
}
.hc-offcanvas-nav.nav-levels-overlap ul li.level-open > .nav-wrapper {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.hc-offcanvas-nav.nav-position-left {
  left: 0;
}
.hc-offcanvas-nav.nav-position-left .nav-container {
  left: 0;
  -webkit-transform: translate3d(-240px, 0, 0);
  transform: translate3d(-240px, 0, 0);
}
.hc-offcanvas-nav.nav-position-left.nav-levels-overlap li .nav-wrapper {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.hc-offcanvas-nav.nav-position-right {
  right: 0;
}
.hc-offcanvas-nav.nav-position-right .nav-container {
  right: 0;
  -webkit-transform: translate3d(240px, 0, 0);
  transform: translate3d(240px, 0, 0);
}
.hc-offcanvas-nav.nav-position-right.nav-levels-overlap li .nav-wrapper {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.hc-offcanvas-nav.nav-position-top {
  top: 0;
}
.hc-offcanvas-nav.nav-position-top .nav-container {
  top: 0;
  width: 100%;
  height: auto;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
.hc-offcanvas-nav.nav-position-top.nav-levels-overlap li .nav-wrapper {
  left: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
.hc-offcanvas-nav.nav-position-bottom {
  top: auto;
  bottom: 0;
}
.hc-offcanvas-nav.nav-position-bottom .nav-container {
  top: auto;
  bottom: 0;
  width: 100%;
  height: auto;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.hc-offcanvas-nav.nav-position-bottom.nav-levels-overlap li .nav-wrapper {
  left: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.hc-offcanvas-nav.nav-open[class*="hc-nav-"] div.nav-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.hc-nav-trigger {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
  top: 20px;
  z-index: 9980;
  width: 30px;
  min-height: 24px;
}
.hc-nav-trigger span {
  width: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}
.hc-nav-trigger span,
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
  display: block;
  position: absolute;
  left: 0;
  height: 4px;
  background: #34495e;
  transition: all 0.2s ease;
}
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
  content: "";
  width: 100%;
}
.hc-nav-trigger span::before {
  top: -10px;
}
.hc-nav-trigger span::after {
  bottom: -10px;
}
.hc-nav-trigger.toggle-open span {
  background: rgba(0, 0, 0, 0);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hc-nav-trigger.toggle-open span::before {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}
.hc-nav-trigger.toggle-open span::after {
  -webkit-transform: rotate(-90deg) translate3d(10px, 0, 0);
  transform: rotate(-90deg) translate3d(10px, 0, 0);
}
.hc-offcanvas-nav::after,
.hc-offcanvas-nav .nav-wrapper::after {
  background: rgba(0, 0, 0, 0.3);
}
.hc-offcanvas-nav .nav-container,
.hc-offcanvas-nav .nav-wrapper,
.hc-offcanvas-nav ul {
  background: #dfb871;
}
.hc-offcanvas-nav h2 {
  font-size: 19px;
  font-weight: normal;
  text-align: right;
  padding: 20px 17px;
  color: #1b3958;
}
.hc-offcanvas-nav a,
.hc-offcanvas-nav .nav-item {
  padding: 14px 17px;
  font-size: 15px;
  color: #fff;
  z-index: 1;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #000;
}
.hc-offcanvas-nav:not(.touch-device) a:hover {
  background: #000;
}
.hc-offcanvas-nav
  ul:first-of-type:not(:first-child)
  > li:first-child:not(.nav-back):not(.nav-close)
  > a {
  border-top: 1px solid #000;
  margin-top: -1px;
}
.hc-offcanvas-nav li {
  text-align: start;
}
.hc-offcanvas-nav li.nav-close a,
.hc-offcanvas-nav li.nav-back a {
  background: #000;
  border-top: 1px solid #295887;
  border-bottom: 1px solid #295887;
}
.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav li.nav-back a:hover {
  background: #000;
}
.hc-offcanvas-nav li.nav-close:not(:first-child) a,
.hc-offcanvas-nav li.nav-back:not(:first-child) a {
  margin-top: -1px;
}
.hc-offcanvas-nav li.nav-parent .nav-item {
  padding-left: 58px;
}
.hc-offcanvas-nav li.nav-close span,
.hc-offcanvas-nav li.nav-parent span.nav-next,
.hc-offcanvas-nav li.nav-back span {
  width: 45px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  cursor: pointer;
  transition: background 0.2s ease;
}
.hc-offcanvas-nav li.nav-close span::before,
.hc-offcanvas-nav li.nav-close span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.hc-offcanvas-nav li.nav-close span::before {
  margin-left: -9px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.hc-offcanvas-nav li.nav-close span::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.hc-offcanvas-nav a[href]:not([href="#"]) > span.nav-next {
  border-left: 1px solid #000;
}
.hc-offcanvas-nav span.nav-next::before,
.hc-offcanvas-nav li.nav-back span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-left: -2px;
  box-sizing: border-box;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.hc-offcanvas-nav span.nav-next::before {
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.hc-offcanvas-nav li.nav-back span::before {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.hc-offcanvas-nav.nav-position-left.nav-open .nav-wrapper {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
}
.hc-offcanvas-nav.nav-position-right.nav-open .nav-wrapper {
  box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
}
.hc-offcanvas-nav.nav-position-right span.nav-next::before {
  margin-left: 0;
  margin-right: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.hc-offcanvas-nav.nav-position-right li.nav-back span::before {
  margin-left: 0;
  margin-right: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.hc-offcanvas-nav.nav-position-top.nav-open .nav-wrapper {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.hc-offcanvas-nav.nav-position-top span.nav-next::before {
  margin-left: 0;
  margin-right: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(-135deg);
  transform: translate(-50%, -50%) rotate(-135deg);
}
.hc-offcanvas-nav.nav-position-top li.nav-back span::before {
  margin-left: 0;
  margin-right: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.hc-offcanvas-nav.nav-position-bottom.nav-open .nav-wrapper {
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
}
.hc-offcanvas-nav.nav-position-bottom span.nav-next::before {
  margin-left: 0;
  margin-right: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.hc-offcanvas-nav.nav-position-bottom li.nav-back span::before {
  margin-left: 0;
  margin-right: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(-135deg);
  transform: translate(-50%, -50%) rotate(-135deg);
}
.hc-offcanvas-nav.nav-levels-expand .nav-container ul .nav-wrapper,
.hc-offcanvas-nav.nav-levels-none .nav-container ul .nav-wrapper {
  box-shadow: none;
  background: transparent;
}
.hc-offcanvas-nav.nav-levels-expand .nav-container ul h2,
.hc-offcanvas-nav.nav-levels-none .nav-container ul h2 {
  display: none;
}
.hc-offcanvas-nav.nav-levels-expand .nav-container ul ul .nav-item,
.hc-offcanvas-nav.nav-levels-none .nav-container ul ul .nav-item {
  font-size: 14px;
}
.hc-offcanvas-nav.nav-levels-expand .nav-container li,
.hc-offcanvas-nav.nav-levels-none .nav-container li {
  transition: background 0.3s ease;
}
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open {
  background: #2e6296;
}
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a {
  border-bottom: 1px solid #295887;
}
.hc-offcanvas-nav.nav-levels-expand .nav-container li.level-open a:hover,
.hc-offcanvas-nav.nav-levels-none .nav-container li.level-open a:hover {
  background: #2f649a;
}
.hc-offcanvas-nav.nav-levels-expand
  .nav-container
  li.level-open
  > .nav-item
  .nav-next::before,
.hc-offcanvas-nav.nav-levels-none
  .nav-container
  li.level-open
  > .nav-item
  .nav-next::before {
  margin-top: 2px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.hc-offcanvas-nav.nav-levels-expand .nav-container span.nav-next::before,
.hc-offcanvas-nav.nav-levels-none .nav-container span.nav-next::before {
  margin-top: -2px;
  -webkit-transform: translate(-50%, -50%) rotate(-135deg);
  transform: translate(-50%, -50%) rotate(-135deg);
}

.hc-offcanvas-nav a,
.hc-offcanvas-nav .nav-item {
  padding: 10px 0px;
}
.nav-container .icon {
  padding: 5px 10px;
  background: none;
  color: #fff;
  min-width: 50px;
  text-align: start;
  font-size: 17px;
}

.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent {
  padding-right: 0;
  padding-left: 0;
}
.hc-offcanvas-nav.nav-levels-overlap ul li.nav-parent a {
  padding-right: 10px;
  padding-left: 10px;
}
